<template>
  <div class="row">
    <div class="col-lg-12">

              <div class="row">
        <div class="col-lg-3 mb-h input-group-for-location-select-in-PortalAdsStats--filters">

                                                                                                           <span
                                                                                                             class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location.data}"></i>
                </span>
          <SelectComponentV3ForUseInLocationSelectors
            :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
            v-if="showFilters"
            no-wrap-selected-option
            class="select-location-in-PortalAdsStats--filters"
            enable-max-option-width
            small-size
            :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
            :show-delete-button="!isSelectedDefaultLocationForRequests"
            v-model="filters.location"
            track-by="name"
            option-id-name="id"
            :no-options-found="$t('general.noResultForSearch')"
            :async-function="requestLocations.bind(this)"
            :placeholder="$t('portalStats.selectLocation')"
            @select="changeLocation"
          >
            <template #dropdown-start>
              <div class="ml-h mt-1">
                <Switch-component
                  v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                  :disabled="isDisabledWithChilds"
                  @input="toggleWithChilds"
                  :label="$t('general.withChild')"
                  class=""
                  v-model="filters.with_childs"
                />
              </div>
            </template>
          </SelectComponentV3ForUseInLocationSelectors>


        </div>
        <div class="col-lg-3">
          <div class="input-group input-group-sm w-100">
                        <span class="input-group-addon">
              <i class="fa fa-file-o" />
            </span>
                            <Multi-select-component
                              v-if="showFilters"
                    v-model="selectedProfileInMultiselect"
                    class="multiselect-in-PortalAdsStats--filters w-100"
                    option-id-name="id"
                    track-by="name"
                    :placeholder="$t('portalStats.selectProfile')"
                    :async-function="requestProfiles.bind(this)"
                    @select="changeProfile"
                  />
            </div>
        </div>
                <div class="col-lg-3" style="z-index: 99998">
                  <div class="input-group input-group-sm w-100">
                        <span class="input-group-addon">
              <i class="fa fa-file-o"/>
            </span>
                    <Multi-select-component
                      v-if="showFilters"
                      v-model="filters.ads"
                      class="multiselect-in-PortalAdsStats--filters w-100"
                      option-id-name="id"
                      track-by="name"
                      :placeholder="$t('portalStats.selectAds')"
                      :async-function="requestAds.bind(this)"
                      @select="changeProfile"
                    />
                  </div>
                </div>
              </div>

              <div class="filters__multiple">
          <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
            <button
              v-if="isFiltersActive"
              class="btn-outline-secondary btn btn-sm  new-modern-style-btn-in-filters mt-0"
              @click.stop.prevent="resetAllFilters"
            >
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </button>
          </div>
        </div>

    </div>

    <!-- <div class="col-lg-12">
      <button class="btn btn-warning btn-md ml-auto float-right" @click="closeAdsStats">
        {{ $t('adsprofile.adsStatsClose') }}
      </button>
    </div> -->
    <!-- <div class="col-lg-12"><br /></div> -->
    <div class="col-lg-12" v-if="isSummaryLoading">
      <!--      <div class="loader loader-backdrop loader-marketing" v-if="isSummaryLoading"></div>-->
      <div v-if="isSummaryLoading"><Loader-spinner centered></Loader-spinner></div>
      <div class="card">
        <div class="card-header">
          <i class="glyphicons glyphicons-stats mr-0"></i>
          {{ $t('portalStats.stats') }}
          <!-- <b>{{ this.adName }}</b> -->
        </div>
      </div>
    </div>
    <div v-else class="col-lg-12">
      <!--      <div class="loader loader-backdrop loader-marketing" v-if="isSummaryLoading"></div>-->
      <div v-if="isSummaryLoading"><Loader-spinner /></div>

      <div class="card">
        <div class="card-header">
          <i class="glyphicons glyphicons-pie-chart glyphicons--shrink mr-0"></i>
          {{ $t('portalStats.stats') }}
          <!-- <b>{{ this.adName }}</b> -->
                    <div class="float-right d-flex align-items-start">
            <div class="mr-1" style="min-width: 300px;">
                                <div class="input-group input-group filters__location">
                    <date-picker
                      v-if="showFilters"
                      class="datepicker_width_full datepicker_height_40"
                      v-model="drPickerDate"
                      type="date"
                      :onChange="handleDateChangeForStat.bind(this)"
                    />
                  </div>
            </div>
            <div >
              <div class="w-100 d-flex flex-column justify-content-end download-button-in-PortalAdsStats">
                <download-extract-v2
                  newXlsAutoColumnsWidth
                  :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.portalAdsAndPollsStatisticSection'))"
                  :data-for-excel="convertToNewExcelExportModuleFormat(formatAllStats(), $t('portalStats.stats'))"
                  v-if="drPickerDate"
                  class="mt-auto float-right"
                  :data="formatAllStats()"
                  :filename="getFileNameForDownloadFiles($t('downloadFilesNames.portalAdsAndPollsStatisticSection'))"
                  :downloads-formats-for-show="{
                    oldCsv: true,
                    newCsv: false,
                    oldXls: false,
                    newXls: true,
                    pdf: true
                  }"
                />
              </div>

            </div>
          </div>
        </div>
        <div class="card-block">
          <!-- <div v-if="this.adName !== ''" class="row">
            <div class="col-lg-12">
              <h4>
                {{ $t('adsprofile.adsStatsbyId') }} {{ $t('adsprofile.adsStatsFor') }}
                <b>{{ this.adName }}</b>
              </h4>
            </div>
            <div class="col-lg-12">
              <br />
            </div>
          </div> -->

          <section>
<!--            <div class="row mb-1">-->
<!--              <div class="col-lg-4 col-sm-4">-->
<!--                <div class="filter">-->
<!--                  <Multi-select-component-->
<!--                    v-model="selectedProfileInMultiselect"-->
<!--                    class="w-100"-->
<!--                    option-id-name="id"-->
<!--                    track-by="name"-->
<!--                    :placeholder="$t('portalStats.selectProfile')"-->
<!--                    :async-function="requestProfiles.bind(this)"-->
<!--                    @select="changeProfile"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-4 col-sm-4">-->
<!--                <div class="filter">-->
<!--                  <SelectComponentV2-->
<!--                    v-model="filters.location"-->
<!--                    track-by="name"-->
<!--                    option-id-name="id"-->
<!--                    :async-function="requestLocations.bind(this)"-->
<!--                    :placeholder="$t('portalStats.selectLocation')"-->
<!--                    @select="changeLocation"-->
<!--                  >-->
<!--                    <template #dropdown-start>-->
<!--                      <span class="select__option select__option_clickable" @click="toggleWithChilds">-->
<!--                        <Checkbox-component-->
<!--                          class="w-100"-->
<!--                          :disabled="isDisabledWithChilds"-->
<!--                          name="with_childs"-->
<!--                          :label="$t('portalStats.selectLocationWithChild')"-->
<!--                          id="with_childs"-->
<!--                          :checked="filters.with_childs === true"-->
<!--                          @click="toggleWithChilds"-->
<!--                        />-->
<!--                      </span>-->
<!--                    </template>-->
<!--                  </SelectComponentV2>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="col-lg-4 col-sm-4">-->
<!--                <div class="filter">-->
<!--                  <Multi-select-component-->
<!--                    v-model="filters.ads"-->
<!--                    class="w-100"-->
<!--                    option-id-name="id"-->
<!--                    track-by="name"-->
<!--                    :placeholder="$t('portalStats.selectAds')"-->
<!--                    :async-function="requestAds.bind(this)"-->
<!--                    @select="changeProfile"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--              <div class="col-lg-6 col-sm-6">-->
<!--                <div class="filter">-->
<!--                  <label>{{ $t('portalStats.selectRange') }}</label>-->
<!--                  <div class="input-group input-group filters__location">-->
<!--                    <date-picker-->
<!--                      class="datepicker_width_full datepicker_height_40"-->
<!--                      v-model="drPickerDate"-->
<!--                      type="date"-->
<!--                      :onChange="handleDateChangeForStat.bind(this)"-->
<!--                    />-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->

<!--              <div class="col-lg-3 col-sm-3 d-flex flex-column justify-content-end submit-width_full">-->
<!--                <download-extract-v2-->
<!--                  newXlsAutoColumnsWidth-->
<!--                  :excel-file-name="getFileNameForDownloadFiles($t('downloadFilesNames.portalAdsAndPollsStatisticSection'))"-->
<!--                  :data-for-excel="convertToNewExcelExportModuleFormat(formatAllStats(), $t('portalStats.stats'))"-->
<!--                  v-if="drPickerDate"-->
<!--                  class="mt-auto float-right"-->
<!--                  :data="formatAllStats()"-->
<!--                  :filename="getFileNameForDownloadFiles($t('downloadFilesNames.portalAdsAndPollsStatisticSection'))"-->
<!--                  :downloads-formats-for-show="{-->
<!--                    oldCsv: true,-->
<!--                    newCsv: false,-->
<!--                    oldXls: false,-->
<!--                    newXls: true,-->
<!--                    pdf: true-->
<!--                  }"-->
<!--                />-->
<!--              </div>-->
<!--              <div class="col-lg-3 col-sm-3 d-flex flex-column justify-content-end submit-width_full">-->
<!--                <button-->
<!--                  class="btn h-100 w-100"-->
<!--                  type="button"-->
<!--                  :class="{ 'btn-success': !submitButton, 'btn-default': submitButton }"-->
<!--                  :disabled="submitButton"-->
<!--                  @click="handleRefresh"-->
<!--                >-->
<!--                  {{ $t('marketing.submit') }}-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
          </section>
          <div v-if="isNoData === false">
            <section class="graph-block">
              <h4 class="text-center mt-2 mb-3">
                {{ $t('adsprofile.adsStatsAllAuthorizationTypesHeader') }}
                <download-extract-v2
                  newXlsAutoColumnsWidth
                  :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalAdsAndPollsStatisticSection')}-${$t('downloadFilesNames.portalAdsAndPollsStatisticSectionAll')}`)"
                  :data-for-excel="convertToNewExcelExportModuleFormat(formatBarWithManyTypesToCSVForUseInSingleChartDataDownloads(allAdsViewsChart, $t('adsprofile.adsStatsAllAuthorizationTypesHeader')), $t('adsprofile.adsStatsAllAuthorizationTypesHeader'))"
                  v-if="true"
                  class="mt-auto float-right"
                  :data="formatBarWithManyTypesToCSVForUseInSingleChartDataDownloads(allAdsViewsChart, $t('adsprofile.adsStatsAllAuthorizationTypesHeader'))"
                  :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalAdsAndPollsStatisticSection')}-${$t('downloadFilesNames.portalAdsAndPollsStatisticSectionAll')}`)"
                  :downloads-formats-for-show="{
                    oldCsv: true,
                    newCsv: false,
                    oldXls: false,
                    newXls: true,
                    pdf: true
                  }"
                />
              </h4>
              <div class="row">
                <div :class="{ 'col-12': !showAdsMultiselect, 'col-8': showAdsMultiselect }">
                  <highcharts :options="allAdsViewsChart" id="allAdsViewsChart"></highcharts>
                  <SavePngButton
                    class="save-icon color-brand"
                    id="allAdsViewsChart"
                    :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalAdsAndPollsStatisticSection')}-${$t('adsprofile.adsStatsAllAuthorizationTypesHeader')}`)"
                  />
                </div>
              </div>
              <!-- <div class="col-12">
                <div class="row mt-2">
                  <table class="table ad-table portal-statistics-block-real-time-data-table">
                    <thead>
                      <tr>
                        <th class="ad-cell">{{ $t('portalStats.date') }}</th>
                        <th>{{ $t('marketing.ad') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(date, dateName) in adCountsList" :key="dateName">
                        <td>
                          {{ dateName }}
                        </td>

                        <td class="p-0">
                          <table class="w-100">
                            <thead>
                              <tr>
                                <th>name</th>
                                <th>count</th>
                                <th>follows</th>
                              </tr>
                            </thead>
                            <tbody v-for="(ad, adIdThisDate) in date" :key="adIdThisDate">
                              <tr>
                                <td class="ad-cell" :key="`${adIdThisDate}-${ad.id_ad}`">{{ getAdName(ad.id_ad) }}</td>
                                <td class="ad-cell" :key="`${adIdThisDate}-${ad.count}`">{{ ad.count }}</td>
                                <td class="ad-cell" :key="`${adIdThisDate}-${ad.follow}`">{{ ad.follow }}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> -->
            </section>
            <section class="graph-block">
              <h4 class="text-center mt-2 mb-3">
                {{ $t('adsprofile.adsStatsSepareteAuthorizationTypesHeader') }}
                <download-extract-v2
                  newXlsAutoColumnsWidth
                  :data-for-excel="convertToNewExcelExportModuleFormat(formatBarWithManyTypesToCSVForUseInSingleChartDataDownloads( separeteAuthTypeAdsViewsChart, $t('adsprofile.adsStatsSepareteAuthorizationTypesHeader')), $t('adsprofile.adsStatsSepareteAuthorizationTypesHeader'))"
                  :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalAdsAndPollsStatisticSection')}-${$t('downloadFilesNames.portalAdsAndPollsStatisticSectionBy')}`)"
                  v-if="true"
                  class="mt-auto float-right"
                  :data="
                    formatBarWithManyTypesToCSVForUseInSingleChartDataDownloads(
                      separeteAuthTypeAdsViewsChart,
                      $t('adsprofile.adsStatsSepareteAuthorizationTypesHeader')
                    )
                  "
                  :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalAdsAndPollsStatisticSection')}-${$t('downloadFilesNames.portalAdsAndPollsStatisticSectionBy')}`)"
                  :downloads-formats-for-show="{
                    oldCsv: true,
                    newCsv: false,
                    oldXls: false,
                    newXls: true,
                    pdf: true
                  }"
                />
              </h4>
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <highcharts :options="separeteAuthTypeAdsViewsChart" id="separeteAuthTypeAdsViewsChart"></highcharts>
                  <SavePngButton
                    class="save-icon color-brand"
                    id="separeteAuthTypeAdsViewsChart"
                    :name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalAdsAndPollsStatisticSection')}-${$t('adsprofile.adsStatsSepareteAuthorizationTypesHeader')}`)"
                  />
                </div>
              </div>
            </section>
          </div>
          <div v-else>
            <div>
              <br />
            </div>
            <div class="text-center">
              {{ $t('general.noDataToDisplay') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-12" v-if="isNoData === false && isSummaryLoading === false">
      <button class="btn btn-warning btn-md ml-auto float-right" @click="closeAdsStats">
        {{ $t('adsprofile.adsStatsClose') }}
      </button>
    </div> -->
  </div>
</template>
<script>
import { Multiselect } from 'vue-multiselect';
import MultiSelectComponent from '../../components/Universal/select/multi-select-component.vue';
import SelectComponent from '../../components/Universal/select/select-component.vue';
import CheckboxComponent from '../../components/Universal/Checkbox-component.vue';
import portalAdsStatsService from '../../services/portalAdsStatsService';
import portalProfilesService from '../../services/portalProfilesService';
import locationService from '../../services/locationService';
import portalAdsService from '../../services/portalAdsService';
import DatePicker from '../../components/date-picker.vue';
import SavePngButton from '../../components/save-png-button.vue';
import helpers from '../../helpers';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

export default {
  name: 'PortalAdsStats',
  components: {
    SelectComponent,
    SavePngButton,
    DatePicker,
    CheckboxComponent,
    MultiSelectComponent,
    SelectComponentV2,
    SwitchComponent,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showFilters: true,
      selectedProfileInMultiselect: [],
      isNoData: false,
      submitButton: true,
      totalConnections: 0,
      uniqClients: 0,
      newUsers: 0,
      notNewUsers: 0,
      adCountsList: false,
      showAdsMultiselect: false,
      adsViewsAndFollowsKeys: false,
      adsDataById: [],
      allAdsViewChartSeries: [
        {
          name: this.$t('adsprofile.adsStatsAllAuthorizationTypesShort'),
          data: [],
          color: '#5c7df8',
          stack: 'all'
        },
        {
          name: this.$t('adsprofile.adsStatsAllFollowsAuthorizationTypesShort'),
          data: [],
          color: '#F91155',
          stack: 'all'
        }
      ],
      separeteAuthTypeAdsViewsChartSeries: [
        {
          name: this.$t('adsprofile.adsStatsAllAuthorizationTypesShort'),
          data: [],
          color: '#5c7df8',
          stack: 'all'
        },
        {
          name: this.$t('adsprofile.adsStatsAllFollowsAuthorizationTypesShort'),
          data: [],
          color: '#F91155',
          stack: 'all'
        }
      ],
      filters: {
        profiles: { name: this.$t('portalStats.allProfiles') },
        ads: [],
        location: {},
        with_childs: true
      },
      drPickerDate: {
        startDate: moment().startOf('month').valueOf(),
        endDate: moment().endOf('month').valueOf()
      }
    };
  },
  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    isDisabledWithChilds() {
      if (!this.filters.location.id || this.filters.location.id === '') {
        return true;
      }
      return false;
    },
    isFiltersActive() {
      return (typeof this.filters.location === 'object' && Object.keys(this.filters.location).length) ||
        (Array.isArray(this.selectedProfileInMultiselect) && this.selectedProfileInMultiselect.length !== 0) ||
        (Array.isArray(this.filters.ads) && this.filters.ads.length !== 0) ||
        this.drPickerDate.startDate !== moment().startOf('month').valueOf() ||
        this.drPickerDate.endDate !== moment().endOf('month').valueOf()
    },
    // locationsList() {
    //   this.$store.state.locationsList.forEach((location) => {
    //     if (location.id === this.$store.state.baseLocation) {
    //       this.filters.location = location;
    //     }
    //   });
    //
    //   return this.$store.state.locationsList;
    // },
    locationsList() {
      const result = [];
      let baseLocation = {};
      const userBaseLocation = JSON.parse(localStorage.getItem('userData')).base_location;
      // const locations = JSON.parse(JSON.stringify(this.$store.state.locationsList));
      // const locations = helpers.sortLocationsList(JSON.parse(JSON.stringify(this.$store.state.locationsList)));
      const locations = JSON.parse(JSON.stringify(this.$store.getters.locationsListSorted));
      // console.log(userBaseLocation)
      locations.forEach((location) => {
        if (location.id === userBaseLocation) {
          // this.filters.location = { name: location.name, id: location.id };
          baseLocation = { name: location.name, id: location.id };
          // console.log(baseLocation)
          return;
        }
        result.push({ name: location.name, id: location.id });
      });
      result.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      result.unshift(baseLocation);
      // console.log(result)
      result.unshift({ name: this.$t('portalStats.allLocations'), id: '' });
      return result;
    },
    selectedProfileInMultiselectForRequest() {
      const result = [];
      if (this.selectedProfileInMultiselect.length === 0) {
        return result;
      }
      for (const profile of this.selectedProfileInMultiselect) {
        result.push(profile.id);
      }
      return result;
    },
    portalProfilesListForFilterMultiselect() {
      const list =
        this.$store.state.portalProfilesList && this.$store.state.portalProfilesList.length ?
          this.$store.state.portalProfilesList :
          [];
      const listForMultiselect = JSON.parse(JSON.stringify(list));
      if (listForMultiselect.length && listForMultiselect[0].id) {
      } else {
        listForMultiselect.shift();
      }
      listForMultiselect.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return listForMultiselect;
    },
    // showAdsMultiselect() {
    //   return this.filters.ads.length;
    // },
    isSummaryLoading() {
      return this.$store.state.loadingSummary;
    },
    portalProfilesList() {
      const list = this.$store.state.portalProfilesList || [];
      if (list.length && list[0].id) {
        list.unshift({ name: this.$t('portalStats.allProfiles') });
      }
      return list;
    },
    portalAdsList() {
      return this.$store.state.portalAdsList || [];
    },
    allAdsViewsChart() {
      return {
        time: {
          useUTC: false
        },
        chart: {
          // type: 'column',
          type: 'spline',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%d.%m.%Y',
            month: '%m.%y'
          },
          crosshair: true
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          useHTML: true,
          xDateFormat: '%d.%m.%Y',
          shared: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0,
            stacking: 'normal'
          }
        },
        series: this.allAdsViewChartSeries
      };
    },
    separeteAuthTypeAdsViewsChart() {
      return {
        time: {
          useUTC: false
        },
        chart: {
          // type: 'column',
          type: 'spline',
          zoomType: 'x',
          panning: true,
          panKey: 'shift'
        },
        title: {
          text: ''
        },
        subtitle: {
          text: this.$t('statistics.pan')
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            day: '%d.%m.%Y',
            month: '%m.%y'
          },
          crosshair: true
        },
        yAxis: {
          // min: 0,
          allowDecimals: false,
          title: {
            text: ''
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px"><b>{point.key}</b></span><table>',
          pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b> {point.y}</b></td></tr>',
          footerFormat: '</table>',
          useHTML: true,
          xDateFormat: '%d.%m.%Y',
          shared: true
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: this.separeteAuthTypeAdsViewsChartSeries
      };
    }
  },
  methods: {
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    redrawFilters(){
      this.showFilters = false;
      this.$nextTick(()=>{
        this.showFilters = true;
      })
    },
    // resetAllFilters() {
    //   this.filters = {
    //     profiles: {name: this.$t('portalStats.allProfiles')},
    //     ads: [],
    //     location: {},
    //     with_childs: true
    //   };
    //   this.drPickerDate = {
    //       startDate: moment().startOf('month').valueOf(),
    //       endDate: moment().endOf('month').valueOf()
    //     };
    //   this.selectedProfileInMultiselect = [];
    //
    //   this.redrawFilters();
    //   this.handleRefresh();
    // },
    resetAllFilters() {
      this.filters = {
        profiles: {name: this.$t('portalStats.allProfiles')},
        ads: [],
        // location: {},
        // with_childs: true
      };
      this.drPickerDate = {
          startDate: moment().startOf('month').valueOf(),
          endDate: moment().endOf('month').valueOf()
        };
      this.selectedProfileInMultiselect = [];
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.with_childs = true;
      } else {
        this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }
      this.redrawFilters();
      this.handleRefresh();
    },
    convertToNewExcelExportModuleFormat(inputDataForOldExcelModule, nameForExcelList){
      return helpers.convertFromOldExcelModuleFormatToNewExcelModuleFormat(inputDataForOldExcelModule, nameForExcelList)
    },
    async requestProfiles(searchQuery) {
      // return portalProfilesService.requestProfiles(searchQuery);
      return portalProfilesService.requestProfilesFilteredByLocationAndWithChilds(this, searchQuery);
    },
    async requestAds(searchQuery) {
      // return portalAdsService.requestAds(searchQuery);
      return portalAdsService.requestAdsFilteredByLocationAndWithChilds(this, searchQuery);
    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    // changeLocation(selectedLocation) {
    //   if (selectedLocation.id === '') {
    //     this.filters.with_childs = true;
    //   }
    //   this.submitButton = false;
    // },
    changeLocation(selectedLocation) {
      if (!selectedLocation) {
        this.filters.with_childs = true;
        this.filters.location = {}
      }
      this.submitButton = false;
      this.handleRefresh();
    },
    toggleWithChilds() {
      // this.filters.with_childs = !this.filters.with_childs;
      // this.submitButton = false;
      this.handleRefresh();
    },
    handleRefresh() {
      this.getRange();
      if (this.filters.ads.length) this.getAdCounts();
    },
    getAdCounts(adId) {
      // Хак, который убирает рекламные бары перед запросом, чтобы они не дублировались
      // this.allAdsViewChartSeries.length = 2;
      portalAdsStatsService.getAdCounts(this, adId);
    },
    getAdName(adId) {
      const adsList = this.portalAdsList;
      const foundAd = adsList.filter((ad) => ad.id === adId);
      return foundAd[0].name || adId;
    },
    closeAdsStats() {
      this.$emit('closeAdsStats');
    },
    getRange(adId) {
      this.submitButton = true;
      this.isNoData = false;
      portalAdsStatsService.getRange(this, 'ad', adId);
    },
    changeProfile() {
      this.submitButton = false;
      this.handleRefresh();
    },
    handleDateChangeForStat() {
      this.submitButton = false;
      this.handleRefresh();
    },
    setTotalConnections(amount) {
      this.totalConnections = amount;
    },
    setUniqClients(amount) {
      this.uniqClients = amount;
    },
    setNewUsers(amount) {
      this.newUsers = amount;
    },
    setNotNewUsers(amount) {
      this.notNewUsers = amount;
    },
    formatBarToCSV(graphData, graphName) {
      if (graphData.series.length === 0) {
        return [];
      }
      // firstRow нужен, чтобы в хедере таблицы появились все даты, даже пустые
      let firstRow = [];
      const rowsMap = graphData.series.map((series) => {
        const rows = new Map([[this.$t('portalStats.type'), series.name]]);
        series.data.forEach((el) => {
          firstRow.push(el[0]);
          rows.set(moment(el[0]).format('DD-MM-YYYY'), el[1]);
        });
        return rows;
      });
      // сортирует по ts, форматируем ts в дату, добавляем заголовок graphName
      firstRow = firstRow.sort().map((el, index, array) => [moment(el).format('DD-MM-YYYY'), '']);
      firstRow.unshift([this.$t('portalStats.type'), graphName]);
      const firtsRowObjFromEntries = Object.fromEntries(firstRow);

      const objFromMap = rowsMap.map((row) => Object.fromEntries(row.entries()));

      return [firtsRowObjFromEntries, ...objFromMap];
    },
    formatBarWithManyTypesToCSV(graphData, graphName) {
      if (graphData.series.length === 0) {
        return [];
      }
      if (!this.allAdsViewsChart.series[0] || !this.allAdsViewsChart.series[0].data) {
        return [];
      }
      // собираем массив со всеми таймпстампами
      // Возьмем дял приммера ттиа авторизации Free. Там может быть таймстампов меньше чем в статистике
      // просмотров по всем дням. И экспортв в pdf сходит с ума если В строке со всем просмотрами
      // например 10 ячеек, а в строке с Free - 5. Поэтому добавляем в конкретные типы авторизации то же количество
      // ячеек с ts что и для всех типов. Если данных за этот ts нет - записываем туда 0
      const allTimestamps = [];
      for (const seriesItems of this.allAdsViewsChart.series[0].data) {
        allTimestamps.push(seriesItems[0]);
      }
      // console.log(allTimestamps)
      // firstRow нужен, чтобы в хедере таблицы появились все даты, даже пустые
      let firstRow = [];
      const rowsMap = graphData.series.map((series) => {
        const rows = {};
        const rowsMap = new Map([[this.$t('portalStats.type'), series.name]]);
        series.data.forEach((el) => {
          const allTimestampsForSeries = [];
          for (const seriesItems of series.data) {
            allTimestampsForSeries.push(seriesItems[0]);
          }
          // если таймстамп из общей кучи есть в серии записываем туда данные, если нет - пустоту
          for (const ts of allTimestamps) {
            firstRow.push(ts);
            if (allTimestampsForSeries.includes(ts)) {
              rows[el[0]] = el[1];
            } else {
              rows[ts] = 0;
            }
          }
        });
        const rowsKeysSorted = Object.keys(rows).sort();
        for (const ts of rowsKeysSorted) {
          // console.log(ts)
          rowsMap.set(moment(parseInt(ts)).format('DD-MM-YYYY'), rows[ts]);
        }
        // console.log(rowsMap)
        return rowsMap;
      });
      // сортирует по ts, форматируем ts в дату, добавляем заголовок graphName
      firstRow = firstRow.sort().map((el, index, array) => [moment(el).format('DD-MM-YYYY'), '']);
      firstRow.unshift([this.$t('portalStats.type'), graphName]);
      const firtsRowObjFromEntries = Object.fromEntries(firstRow);

      const objFromMap = rowsMap.map((row) => Object.fromEntries(row.entries()));

      return [firtsRowObjFromEntries, ...objFromMap];
    },

    formatBarWithManyTypesToCSVV2(graphData, graphName) {
      if (graphData.series.length === 0) {
        return [];
      }
      if (!this.allAdsViewsChart.series[0] || !this.allAdsViewsChart.series[0].data) {
        return [];
      }
      // собираем массив со всеми таймпстампами
      // Возьмем дял приммера ттиа авторизации Free. Там может быть таймстампов меньше чем в статистике
      // просмотров по всем дням. И экспортв в pdf сходит с ума если В строке со всем просмотрами
      // например 10 ячеек, а в строке с Free - 5. Поэтому добавляем в конкретные типы авторизации то же количество
      // ячеек с ts что и для всех типов. Если данных за этот ts нет - записываем туда 0
      // const allTimestamps = [];
      // for (const seriesItems of this.allAdsViewsChart.series[0].data) {
      //   allTimestamps.push(seriesItems[0]);
      // }

      let allTimestamps = [];
      const allTimestampsSet = new Set();
      for (const index in this.allAdsViewsChart.series) {
        for (const seriesItems of this.allAdsViewsChart.series[index].data) {
          allTimestampsSet.add(seriesItems[0]);
        }
      }
      allTimestamps = Array.from(allTimestampsSet);

      // console.log(allTimestamps)
      // firstRow нужен, чтобы в хедере таблицы появились все даты, даже пустые
      let firstRow = [];
      const rowsMap = graphData.series.map((series) => {
        const rows = {};
        const rowsMap = new Map([[this.$t('portalStats.type'), series.name]]);
        series.data.forEach((el) => {
          const allTimestampsForSeries = [];
          for (const seriesItems of series.data) {
            allTimestampsForSeries.push(seriesItems[0]);
          }
          // если таймстамп из общей кучи есть в серии записываем туда данные, если нет - пустоту
          for (const ts of allTimestamps) {
            firstRow.push(ts);
            if (allTimestampsForSeries.includes(ts)) {
              rows[el[0]] = el[1];
            } else {
              rows[ts] = 0;
            }
          }
        });
        const rowsKeysSorted = Object.keys(rows).sort();
        for (const ts of rowsKeysSorted) {
          // console.log(ts)
          rowsMap.set(moment(parseInt(ts)).format('DD-MM-YYYY'), rows[ts]);
        }
        // console.log(rowsMap)
        return rowsMap;
      });
      // сортирует по ts, форматируем ts в дату, добавляем заголовок graphName
      firstRow = firstRow.sort().map((el, index, array) => [moment(el).format('DD-MM-YYYY'), '']);
      firstRow.unshift([this.$t('portalStats.type'), graphName]);
      const firtsRowObjFromEntries = Object.fromEntries(firstRow);

      const objFromMap = rowsMap.map((row) => Object.fromEntries(row.entries()));

      return [firtsRowObjFromEntries, ...objFromMap];
    },

    formatBarWithManyTypesToCSVForUseInSingleChartDataDownloads(graphData, graphName) {
      if (graphData.series.length === 0) {
        return [];
      }
      if (!this.allAdsViewsChart.series[0] || !this.allAdsViewsChart.series[0].data) {
        return [];
      }
      // собираем массив со всеми таймпстампами из графика
      // В какой то серии будут таймстампы, котоых нет в других
      // И экспортв в pdf сходит с ума если В строке с каим-либо типом просмотров
      // например 10 ячеек, а в другой  - 5. Поэтому добавляем в конкретные типы авторизации то же количество
      // ячеек с ts что и для всех типов. Если данных за этот ts нет - записываем туда 0
      let allTimestamps = [];
      const allTimestampsSet = new Set();
      for (const index in graphData.series) {
        for (const seriesItems of graphData.series[index].data) {
          allTimestampsSet.add(seriesItems[0]);
        }
      }
      allTimestamps = Array.from(allTimestampsSet);

      // console.log(allTimestamps);
      // console.log(allTimestamps)
      // firstRow нужен, чтобы в хедере таблицы появились все даты, даже пустые
      let firstRow = [];
      const rowsMap = graphData.series.map((series) => {
        const rows = {};
        const rowsMap = new Map([[this.$t('portalStats.type'), series.name]]);
        series.data.forEach((el) => {
          const allTimestampsForSeries = [];
          for (const seriesItems of series.data) {
            allTimestampsForSeries.push(seriesItems[0]);
          }
          // если таймстамп из общей кучи есть в серии записываем туда данные, если нет - пустоту
          for (const ts of allTimestamps) {
            firstRow.push(ts);
            if (allTimestampsForSeries.includes(ts)) {
              rows[el[0]] = el[1];
            } else {
              rows[ts] = 0;
            }
          }
        });
        const rowsKeysSorted = Object.keys(rows).sort();
        for (const ts of rowsKeysSorted) {
          // console.log(ts)
          rowsMap.set(moment(parseInt(ts)).format('DD-MM-YYYY'), rows[ts]);
        }
        // console.log(rowsMap)
        return rowsMap;
      });
      // сортирует по ts, форматируем ts в дату, добавляем заголовок graphName
      firstRow = firstRow.sort().map((el, index, array) => [moment(el).format('DD-MM-YYYY'), '']);
      firstRow.unshift([this.$t('portalStats.type'), graphName]);
      const firtsRowObjFromEntries = Object.fromEntries(firstRow);

      const objFromMap = rowsMap.map((row) => Object.fromEntries(row.entries()));

      return [firtsRowObjFromEntries, ...objFromMap];
    },

    // formatAllStats() {
    //   return [
    //     ...this.formatBarToCSV(this.allAdsViewsChart, this.$t('adsprofile.adsStatsAllAuthorizationTypesHeader')),
    //     ...this.formatBarWithManyTypesToCSV(
    //       this.separeteAuthTypeAdsViewsChart,
    //       this.$t('adsprofile.adsStatsSepareteAuthorizationTypesHeader')
    //     )
    //   ];
    // }

    formatAllStats() {
      return [
        ...this.formatBarWithManyTypesToCSVForUseInSingleChartDataDownloads(this.allAdsViewsChart, this.$t('adsprofile.adsStatsAllAuthorizationTypesHeader')),
        ...this.formatBarWithManyTypesToCSVV2(
          this.separeteAuthTypeAdsViewsChart,
          this.$t('adsprofile.adsStatsSepareteAuthorizationTypesHeader')
        )
      ];
    }

  },
  // created() {
  //   this.$store.commit('portalProfilesListClean');
  //   this.$store.commit('portalAdsListClean');
  //   // if (this.portalProfilesList.length < 1) portalAdsService.getPortalAds(this);
  //   if (this.portalProfilesList.length < 1) portalAdsService.getPortalAdsWithNoPagination(this);
  //   if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);
  //   // NOTE: getting data from /api/admin/range
  //   // if (this.adId) {
  //   //   this.filters.ads = this.portalAdsList.filter((ad) => ad.id === this.adId);
  //   // }
  //   this.getRange(this.adId);
  // }
  created() {
    this.$store.commit('portalProfilesListClean');
    this.$store.commit('portalAdsListClean');
    // if (this.portalProfilesList.length < 1) portalAdsService.getPortalAds(this);
    if (this.portalProfilesList.length < 1) portalAdsService.getPortalAdsWithNoPagination(this);
    if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);
    // NOTE: getting data from /api/admin/range
    // if (this.adId) {
    //   this.filters.ads = this.portalAdsList.filter((ad) => ad.id === this.adId);
    // }
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location = {};
        this.filters.with_childs = true;
      } else {
        this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.with_childs = withChildsForFiltering
      }
    }

    this.getRange(this.adId);
  }
};
</script>

<style lang="scss" scoped>
.disbale-with-childs {
  cursor: not-allowed;
}
.fix-z-index {
  z-index: 2;
}
.graph-block {
  padding: 20px 0 0 0;
}
.datepicker_width_full {
  width: 100%;
}

.traffic-profit {
  flex-grow: 1;
  margin-top: 10px;
  margin-bottom: 90px;
}

.category-profit {
  flex-grow: 1;
  margin-top: 20px;
}

.text-active {
  color: rgba(255, 143, 7, 1);
}

.text-loyalty {
  color: rgba(253, 216, 53, 1);
}

.text-returned {
  color: rgba(255, 143, 7, 0.8);
}

.text-new {
  color: #81c784;
}

.value {
  font-size: 1.7rem;

  &--big {
    font-size: 2.4rem;
  }

  &--large {
    font-size: 2rem;
  }

  &--title {
    font-size: 0.9rem;
  }
}

.card-new {
  border-top: 4px solid #81c784;
}

.card-returned {
  border-top: 4px solid rgba(255, 143, 7, 0.8);
}

.card-unique {
  border-top: 4px solid rgb(124, 181, 236);
}

.text-returned {
  color: rgba(255, 143, 7, 0.8);
}

.card-default {
  border-top: 4px solid rgba(183, 183, 183, 0.5);
}

.card-summary {
  margin-top: -30px;
}

.text-profit {
  font-size: 1.5rem;
}

.rateHalf {
  height: 300px;
}

.filter-table {
  width: 140px;
}

.text-muted {
  color: #94a0b2 !important;
}

.text-muted-light {
  color: #bcc5d0 !important;
}

.submit {
  & button {
    // position: relative;
    margin-top: 28px;
    height: 40px;
    width: 100%;
  }
}

/*.loader-backdrop {*/
/*  &:after {*/
/*    position: absolute;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    top: 10%;*/
/*    border: 5px solid #e6e6e6;*/
/*    border-top-color: #1bb3a0;*/
/*  }*/
/*}*/

.vendor-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;

  & tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 400px;
  }

  & thead tr {
    display: block;
  }

  & th,
  & td {
    padding: 5px;
    text-align: left;
    width: 150px;
  }
  & .vendor-cell {
    width: 300px;
  }
}

.save-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
</style>

<style lang="scss">
    .download-button-in-PortalAdsStats button{
        height: 40px !important;
    width: 100%;
  }
  .multiselect-in-PortalAdsStats--filters {
  min-height: 23px !important;
}

.multiselect-in-PortalAdsStats--filters .multi-select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 23px !important;
  align-items: center;
}

.multiselect-in-PortalAdsStats--filters .multi-select__input {
  min-height: 23px !important;
  margin-bottom: 0px !important;
}

.multiselect-in-PortalAdsStats--filters .arrow {
  min-height: 23px !important;
}

.multiselect-in-PortalAdsStats--filters .multi-select__tag {
  margin-bottom: 0px;
  height: 22px !important;
}

    .input-group-for-location-select-in-PortalAdsStats--filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;
  }

  .select-location-in-PortalAdsStats--filters .select__list-container {
    width: 400px;
  }

.loader-marketing:after {
  position: absolute;
  /*top: calc(50vh);*/
  top: 30px;
}
.submit-width_full {
  & button {
    // position: relative;
    margin-top: 28px;
    height: 40px;
    width: 100%;
  }
}
</style>
<!--background-image: url(https://leroy-moskva.ru/wp-content/uploads/2018/06/Lerua-Merlen-Lefortovo-shema.jpg);-->
<!--background-size: contain;-->
